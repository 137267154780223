import {
  FormPatientInfoElementVO,
  AddressVO,
  PracticeInfoVO,
  OnboardingOptionsVO,
} from "@libs/api/generated-api";
import { FC, useMemo } from "react";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Trans, useTranslation } from "react-i18next";
import { FormFieldSelectPronoun } from "@libs/components/UI/FormFieldSelectPronoun";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { FormFieldUserSsnInput } from "@libs/components/UI/FormFieldUserSsnInput";
import { FormFieldPhoneInput } from "@libs/components/UI/FormFieldPhoneInput";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { formatISODate } from "@libs/utils/date";
import { PatientInfoFormValidation } from "components/PatientForms/FormElements/patientInfoFormValidation";
import { FormFieldSelectGender } from "components/UI/FormFieldSelectGender";
import { useUSStateOptions } from "hooks/useUSStateOptions";
import { PatientInfoFormSection } from "components/PatientForms/FormElements/PatientInfoFormSection";
import { useHandleError } from "api/handleErrorResponse";
import { env } from "env";
import { EditablePatientInfoFields, ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { usePronounOptions } from "hooks/usePronounOptions";

const createAddressValue = (overrides: Partial<AddressVO>): AddressVO => ({
  city: "",
  state: "",
  zip: "",
  address1: "",
  ...overrides,
});

export type PatientInfoFormElementProps = {
  element: FormPatientInfoElementVO;
  validation: PatientInfoFormValidation;
  patient: ExistingPatientInfo;
  referralOptions: OnboardingOptionsVO["referralOptions"];
  editableFields: EditablePatientInfoFields;
  onUpdate: (updates: Partial<EditablePatientInfoFields>) => void;
};

export const PatientInfoFormElement: FC<PatientInfoFormElementProps & { practice: PracticeInfoVO }> = ({
  element,
  onUpdate,
  patient,
  referralOptions,
  editableFields,
  validation,
  practice,
}) => {
  const handleError = useHandleError();
  const stateOptions = useUSStateOptions(editableFields.address?.state);
  const { t } = useTranslation();
  const translatedPronounOptions = usePronounOptions();

  const patientReferralOptions = useMemo(() => {
    return referralOptions.map((option) => ({
      label: option,
      value: option,
    }));
  }, [referralOptions]);

  return (
    <div className="flex flex-col gap-y-12">
      <PatientInfoFormSection title={t("Personal")}>
        <FormFieldInput readOnly label={t("First Name")} disabled value={patient.firstName} />
        {element.optionalFields.includes("MIDDLE_NAME") ? (
          <FormFieldInput
            label={t("Middle Name")}
            value={editableFields.middleName}
            onChange={(e) => onUpdate({ middleName: e.target.value })}
          />
        ) : null}
        <FormFieldInput readOnly label={t("Last Name")} disabled value={patient.lastName} />
        <FormFieldInput
          label={t("Preferred Name")}
          value={editableFields.preferredName}
          onChange={(e) => onUpdate({ preferredName: e.target.value })}
        />
        <FormFieldSelectGender
          label={t("Gender")}
          required={!patient.gender}
          value={editableFields.gender}
          error={validation.gender.$error}
          onChange={(option) => onUpdate({ gender: option?.value })}
        />
        {element.optionalFields.includes("PREFERRED_PRONOUNS") ? (
          <FormFieldSelectPronoun
            label={t("Preferred Pronouns")}
            onChange={(option) => onUpdate({ preferredPronouns: option?.value })}
            value={editableFields.preferredPronouns}
            options={translatedPronounOptions}
          />
        ) : null}
        <FormFieldInput
          label={t("Date of Birth")}
          disabled
          readOnly
          value={formatISODate(patient.dob)}
          Icon={CalendarIcon}
        />
        {patient.hasSsn ? null : (
          <FormFieldUserSsnInput
            label="SSN"
            userId={0}
            value={editableFields.ssn}
            onSsnError={handleError}
            onValueChange={(value) => {
              onUpdate({
                ssn: value,
              });
            }}
          />
        )}
        {patient.hasReferredBy ? null : (
          <FormFieldSelect
            display="label"
            label={t("How did you hear about us?")}
            onItemSelected={(value) => {
              onUpdate({ referredBy: value });
            }}
            options={patientReferralOptions}
            value={editableFields.referredBy}
          />
        )}
      </PatientInfoFormSection>
      {patient.hasContact ? null : (
        <PatientInfoFormSection title={t("Contact")}>
          {/* if a patient doesn't have an email we don't don't to allow them to change their contact method */}
          {patient.email ? (
            <FormFieldInput label={t("Email")} disabled readOnly type="email" value={patient.email} />
          ) : null}
          <FormFieldPhoneInput
            label={t("Cell Phone")}
            value={editableFields.mobilePhone}
            error={validation.mobilePhone.$error}
            onValueChange={(val) => {
              onUpdate({ mobilePhone: val });
            }}
          />
          <FormFieldPhoneInput
            label={t("Home Phone")}
            value={editableFields.homePhone}
            error={validation.homePhone.$error}
            onValueChange={(val) => {
              onUpdate({ homePhone: val });
            }}
          />
          <FormFieldPhoneInput
            label={t("Work Phone")}
            value={editableFields.workPhone}
            error={validation.workPhone.$error}
            onValueChange={(val) => {
              onUpdate({ workPhone: val });
            }}
          />
          <div className="flex flex-col gap-4">
            <FormFieldAddressAutocomplete
              apiKey={env.REACT_APP_GOOGLE_API_KEY}
              required={!patient.hasAddress}
              error={validation.address.address1.$error}
              label={t("Address")}
              onSelect={(address) => {
                if (Object.values(address).some((item) => item.trim().length > 0)) {
                  onUpdate({
                    address: {
                      city: address.city,
                      state: address.state,
                      address1: address.address,
                      zip: address.zip,
                    },
                  });
                } else {
                  onUpdate({ address: undefined });
                }
              }}
            />
            <FormFieldInput
              label={t("app.page.labels.apartmentUnitSuitFloor")}
              value={editableFields.address?.address2}
              onChange={(e) => {
                onUpdate({
                  address: createAddressValue({
                    ...editableFields.address,
                    address2: e.target.value,
                  }),
                });
              }}
            />
            <FormFieldInput
              label={t("City")}
              value={editableFields.address?.city}
              error={validation.address.city.$error}
              onChange={(e) => {
                onUpdate({
                  address: createAddressValue({
                    ...editableFields.address,
                    city: e.target.value,
                  }),
                });
              }}
            />
            <FormFieldSelect
              label={t("State")}
              menuPlacement="top"
              value={editableFields.address?.state}
              error={validation.address.state.$error}
              onChange={(option) => {
                onUpdate({
                  address: createAddressValue({
                    ...editableFields.address,
                    state: option?.value ?? "",
                  }),
                });
              }}
              options={stateOptions}
            />
            <FormFieldInput
              label={t("Zipcode")}
              value={editableFields.address?.zip}
              error={validation.address.zip.$error}
              onChange={(e) => {
                onUpdate({
                  address: createAddressValue({
                    ...editableFields.address,
                    zip: e.target.value,
                  }),
                });
              }}
            />

            <p className="text-xs">
              <Trans
                i18nKey="forms.patientInfo.disclaimer"
                values={{ practiceName: practice.name }}
                tOptions={{ interpolation: { escapeValue: true } }}
                shouldUnescape
              />
            </p>
          </div>
        </PatientInfoFormSection>
      )}
    </div>
  );
};
